import { render, staticRenderFns } from "./SearchPostTouristInfo.vue?vue&type=template&id=ec14e654&scoped=true&"
import script from "./SearchPostTouristInfo.vue?vue&type=script&lang=js&"
export * from "./SearchPostTouristInfo.vue?vue&type=script&lang=js&"
import style0 from "./SearchPostTouristInfo.vue?vue&type=style&index=0&id=ec14e654&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ec14e654",
  null
  
)

export default component.exports