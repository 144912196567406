<template>
    <section class="promo">
        <div class="c-sec-titlebar">
            <span class="eng-img">
                <img src="../../assets/img/ttl_img_pn.svg" alt="">
            </span>
            <h2 class="txt">お得なキャンペーン</h2>
        </div>
        <div v-if="posts" class="p-slider">
            <div class="p-slider-body" data-slider-id="promo">
                <slick ref="slick" :options="slickOptions">
                    <div v-for="(post, index) in posts" :key="index + 1" class="c-card c-pnCard">
                        <template v-if="post.link">
                            <a :href="post.link" target="_blank" rel="noopener noreferrer">
                                <figure class="c-card-thum">
                                    <img :src="post.imageurl" :alt="post.title">
                                </figure>
                                <h2 class="c-card-ttl">{{ post.title }}</h2>
                                <p class="c-card-desc">{{ post.description }}</p>
                            </a>
                        </template>
                        <template v-else>
                            <div class="c-pnCard__no-link">
                                <figure class="c-card-thum">
                                    <img :src="post.imageurl" :alt="post.title">
                                </figure>
                                <h2 class="c-card-ttl">{{ post.title }}</h2>
                                <p class="c-card-desc">{{ post.description }}</p>
                            </div>
                        </template>
                    </div>
                </slick>
            </div>
            <template v-if="posts.length >= 3">
                <button type="button" class="p-slider-nav__prev" data-slider-id="promo" @click="sliderPrev">
                    <i class="icon-arrow_left"></i>
                </button>
                <button type="button" class="p-slider-nav__next" data-slider-id="promo" @click="sliderNext">
                    <i class="icon-arrow_right"></i>
                </button>
            </template>
        </div>
    </section>
</template>

<script>
    import Slick from 'vue-slick'

    export default {
        name: "SearchPostCampaign",
        components: {
            Slick
        },
        props: {
            posts: {
                type: Array
            }
        },
        data: function () {
            return {
                slickOptions: {
                    autoplay: true,
                    autoplaySpeed: 3000,
                    speed: 1000,
                    pauseOnHover: false,
                    pauseOnFocus: false,
                    swipe: true,
                    swipeToSlide: false,
                    slidesToShow: 3,
                    arrows: false,
                    infinite: true,
                    responsive: [
                        {
                            breakpoint: 1200,
                            settings: {
                                slidesToShow: 2,
                            }
                        },
                        {
                            breakpoint: 700,
                            settings: {
                                slidesToShow: 1,
                                centerMode: true,
                                centerPadding: '7%'
                            }
                        }
                    ]
                }
            }
        },
        methods: {
            sliderNext: function () {
                this.$refs.slick.next();
            },
            sliderPrev: function () {
                this.$refs.slick.prev();
            }
        }
    }
</script>

<style lang="scss" scoped>
    .p-slider-body {
        img {
            width: 100%;
        }
    }
    .c-pnCard {
        &__no-link {
            display: flex;
            flex-direction: column;
            height: 100%;
            transition: .2s all;
            padding-bottom: 4rem;
            border-bottom: 2px solid #ECECEC;
            position: relative;
        }
    }
</style>
