<template>
    <transition name="fade">
        <div class="modal-overlay" @click="modalClose"></div>
    </transition>
</template>

<script>
    export default {
        name: "ModalOverLay",
        methods: {
            modalClose: function () {
                this.$emit('modal-close');
            }
        }
    }
</script>

<style lang="scss" scoped>
    .fade {
        &-enter-active, &-leave-active {
            will-change: opacity;
            transition: opacity 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
        }
        &-enter, &-leave-to {
            opacity: 0;
        }
    }
</style>
