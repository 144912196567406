import Vue from 'vue'
import App from './App.vue'
import axios from 'axios'
import VueAxios from 'vue-axios'
import VueScrollTo from 'vue-scrollto'

Vue.config.productionTip = false;
Vue.use(VueAxios, axios);
Vue.use(VueScrollTo);
require('@/assets/scss/style.scss');

new Vue({
    render: h => h(App),
}).$mount('#app');
