<template>
    <section class="touristInfo">
        <div class="c-sec-titlebar">
            <span class="eng-img">
                <img src="../../assets/img/ttl_img_ti.svg" alt="">
            </span>
            <h2 class="txt">観光情報</h2>
        </div>
        <div v-if="posts" class="p-slider">
            <div class="p-slider-body" data-slider-id="tourist">
                <slick ref="slick" :options="slickOptions">
                    <div v-for="(post, index) in posts" :key="index + 1" class="c-card c-tiCard">
                        <template v-if="post.link">
                            <a :href="post.link" target="_blank" rel="noopener noreferrer">
                                <figure class="c-card-thum">
                                    <img :src="post.imageurl" :alt="post.title">
                                </figure>
                                <h2 class="c-card-ttl cl-text">
                                    <span class="cl-text-inr">{{ post.title }}</span>
                                </h2>
                                <p class="c-card-desc c-tiCard-desc">{{ post.description }}</p>
                                <div class="c-tiCard-info">
                                    <p class="c-tiCard-info__inr">
                                        <i class="icon-infomation02"></i>
                                        <span>{{ post.access }}</span>
                                    </p>
                                </div>
                            </a>
                        </template>
                        <template v-else>
                            <div class="c-tiCard__no-link">
                                <figure class="c-card-thum">
                                    <img :src="post.imageurl" :alt="post.title">
                                </figure>
                                <h2 class="c-card-ttl cl-text">
                                    <span class="cl-text-inr">{{ post.title }}</span>
                                </h2>
                                <p class="c-card-desc c-tiCard-desc">{{ post.description }}</p>
                                <div class="c-tiCard-info">
                                    <p class="c-tiCard-info__inr">
                                        <i class="icon-infomation02"></i>
                                        <span>{{ post.access }}</span>
                                    </p>
                                </div>
                            </div>
                        </template>
                    </div>
                </slick>
            </div>
            <template v-if="posts.length >= 3">
                <button type="button" class="p-slider-nav__prev" data-slider-id="tourist" @click="sliderPrev">
                    <i class="icon-arrow_left"></i>
                </button>
                <button type="button" class="p-slider-nav__next" data-slider-id="tourist" @click="sliderNext">
                    <i class="icon-arrow_right"></i>
                </button>
            </template>
        </div>
    </section>
</template>

<script>
    import Slick from 'vue-slick'

    export default {
        name: "SearchPostTouristInfo",
        components: {
            Slick
        },
        props: {
            posts: {
                type: Array
            }
        },
        data: function () {
            return {
                slickOptions: {
                    autoplay: true,
                    autoplaySpeed: 3000,
                    speed: 1000,
                    pauseOnHover: false,
                    pauseOnFocus: false,
                    swipe: true,
                    swipeToSlide: false,
                    slidesToShow: 3,
                    arrows: false,
                    infinite: true,
                    responsive: [
                        {
                            breakpoint: 1200,
                            settings: {
                                slidesToShow: 2,
                            }
                        },
                        {
                            breakpoint: 700,
                            settings: {
                                slidesToShow: 1,
                                centerMode: true,
                                centerPadding: '7%'
                            }
                        }
                    ]
                }
            }
        },
        methods: {
            sliderNext: function () {
                this.$refs.slick.next();
            },
            sliderPrev: function () {
                this.$refs.slick.prev();
            }
        }
    }
</script>

<style lang="scss" scoped>
    .p-slider-body {
        img {
            width: 100%;
        }
    }
    .c-tiCard {
        &__no-link {
            display: flex;
            flex-direction: column;
            height: 100%;
            transition: .2s all;
        }
    }
</style>
