<template>
    <div class="resultsSec" v-if="baseData">
        <div class="inr">
            <div class="resultsSec-top">
                <div class="resultsSec-box departure">
                    <i class="rst-icon">
                        <img src="../../assets/img/map_pin_s.svg" alt="">
                    </i>
                    <p class="rst-place">{{ baseData.start }}</p>
                    <span class="rst-label">出発地</span>
                </div>
                <div class="resultsSec-top__arrow">
                    <img src="../../assets/img/arrow_right.svg" alt="">
                </div>
                <div class="resultsSec-box destination">
                    <i class="rst-icon">
                        <img src="../../assets/img/map_pin_g.svg" alt="">
                    </i>
                    <p class="rst-place">{{ baseData.end }}</p>
                    <span class="rst-label">目的地</span>
                </div>
            </div><!-- /.resultsSec-top -->

            <div v-if="(baseData.info && baseData.info.name) || baseData.time" class="resultsSec-mid">
                <div class="resultsSec-box">
                    <p v-if="baseData.time" class="rsm-reqt">
                        <span class="rsm-reqt__label">所要時間</span>
                        <time class="rsm-reqt__time">{{ baseData.time }}</time>
                    </p>
                    <div v-if="baseData.info && baseData.info.name" class="rsm-root">
                        <template v-for="(name, index) in baseData.info.name">
                            <p class="rsm-root__place" :key="`place-${index + 1}`">{{ name }}</p>
                            <div class="rsm-root__info" :key="`info-${index + 1}`">
                                <span class="rsm-root__info-inr">
                                    <!--<i class="icon-bus rinfo-icon"></i>-->
                                    <time class="rinfo-time">{{ baseData.info.time[index] }}</time>
                                </span>
                            </div>
                        </template>
                        <p class="rsm-root__place">{{ baseData.end }}</p>
                    </div>
                </div>
            </div><!-- /.resultsSec-mid -->

            <div class="resultsSec-btm">
                <div class="resultsSec-box">
                    <div class="sw-tab">
                        <div class="sw-tab-list">
                            <button v-if="baseData.route" type="button" class="rsb-tabBtn sw-tab-btn" :class="tabActive === 'bus' ? 'is-tab-current' : ''" data-tab-id="bus" @click="tabActive = 'bus'">
                                <i class="icon-bus rsb-tabBtn__icon"></i>
                                <span class="rsb-tabBtn__txt">バス</span>
                            </button>
                            <button type="button" class="rsb-tabBtn sw-tab-btn" :class="tabActive === 'taxi' ? 'is-tab-current' : ''" data-tab-id="taxi" :data-tab-single="!baseData.route ? 'true' : 'false'" @click="tabActive = 'taxi'">
                                <i class="icon-taxi rsb-tabBtn__icon"></i>
                                <span class="rsb-tabBtn__txt">車</span>
                            </button>
                        </div><!-- /.sw-tab-list -->

                        <div class="sw-tab-content">
                            <div v-if="baseData.route" class="sw-tab-panel" :class="tabActive === 'bus' ? 'is-tab-current' : ''" data-tab-id="bus">
                                <div class="routeList">
                                    <div v-for="(route, index) in baseData.route" :key="index + 1" class="routeList-item">
                                        <p class="routeList-item__place">
                                            <span>{{ routeData[index].name }}</span>
                                        </p>
                                        <div class="routeList-item__info">
                                            <time v-if="typeof baseData.interval !== 'undefined' && baseData.interval[index]" class="time">
                                                {{ baseData.interval[index] }}
                                            </time>
                                            <div class="rid-detail ">
                                                <p v-if="routeData[index].caution" class="caution">{{ routeData[index].caution }}</p>
                                                <p v-if="routeData[index].transport">{{ routeData[index].transport }}</p>
                                                <p v-if="routeData[index].access">{{ routeData[index].access }}</p>
                                                <div v-if="routeData[index].information" class="note">
                                                    <p v-html="routeData[index].information.description"></p>
                                                    <div v-if="routeData[index].information.name && routeData[index].information.url" class="btn-right">
                                                        <a :href="routeData[index].information.url" class="btn-ghost" target="_blank">
                                                            {{ routeData[index].information.name }}
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="rid-right">
                                                <button v-if="routeData[index].timetable" type="button" class="btn-open-modal p-modal-open" data-modal-id="time-table-01" @click="modalOpen(index)">
                                                    <i class="icon-time_table"></i>時刻表を見る
                                                </button>
                                            </div>
                                        </div>
                                    </div><!-- /.routeList-item -->
                                    <div class="routeList-item">
                                        <p class="routeList-item__place"><span>{{ baseData.end }}</span></p>
                                    </div><!-- /.routeList-item -->
                                </div><!-- /.routeList -->

                                <div class="routeList-map">
                                    <iframe :src="baseData.bus.map" width="600" height="450" frameborder="0" style="border:0;" allowfullscreen="" aria-hidden="false" tabindex="0" ref="busMap"></iframe>
                                </div>

                            </div>
                            <div class="sw-tab-panel" :class="tabActive === 'taxi' ? 'is-tab-current' : ''" data-tab-id="taxi">
                                <div class="rsb-map">
                                    <iframe :src="baseData.car.map" width="600" height="450" frameborder="0" style="border:0;" allowfullscreen="" aria-hidden="false" tabindex="0" ref="carMap"></iframe>
                                </div>
                            </div>
                        </div><!-- /.sw-tab-content -->
                    </div><!-- /.sw-tab -->
                </div>
            </div><!-- /.resultsSec-btm -->
        </div><!-- /.inr -->
        <modal-wrap v-show="isModal" :modal-title="modalTitle" :modal-text="modalText" :modal-data="modalData" class="is-open" style="display: flex;" @modal-close="modalClose"></modal-wrap>
        <modal-over-lay v-show="isModal" @modal-close="modalClose"></modal-over-lay>
    </div><!-- /.resultsSec -->
</template>

<script>
    import ModalWrap from '../modal/ModalWrap'
    import ModalOverLay from '../modal/ModalOverLay'

    export default {
        name: "SearchResult",
        components: {
            ModalWrap,
            ModalOverLay
        },
        props: {
            baseData: {
                type: Object
            },
            routeData: {
                type: Array
            }
        },
        data: function () {
            return {
                tabActive: 'bus',
                modalTitle: null,
                modalText: null,
                modalData: null,
                isModal: false
            }
        },
        mounted: function () {
            if (!this.baseData.route) {
                this.tabActive = 'taxi';
            }

            /* eslint-disable no-console */
            console.log(this.routeData);
        },
        watch: {
            tabActive: function () {
                if (this.baseData.route) {
                    this.$refs.busMap.setAttribute('src', this.baseData.bus.map);
                }
                this.$refs.carMap.setAttribute('src', this.baseData.car.map);
            }
        },
        methods: {
            modalOpen: function (i) {
                /* eslint-disable no-console */
                // console.log(this.routeData[0]);

                // this.modalTitle = title;
                // this.modalText = text;
                // this.modalData = data;

                this.modalTitle = this.routeData[i].name;
                this.modalText = this.routeData[i].access;
                this.modalData = this.routeData[i].timetable;

                document.body.classList.add('is-modal-open', 'is-no-scroll');

                document.getElementsByClassName('p-modal')[0].classList.add('is-open');
                //
                this.isModal = true;
            },
            modalClose: function () {
                document.body.classList.remove('is-modal-open');
                document.body.classList.remove('is-no-scroll');
                document.getElementsByClassName('p-modal')[0].classList.remove('is-open');
                this.isModal = false;
                this.modalData = null;
            }
        }
    }
</script>

<style lang="scss" scoped>
    .resultsSec-btm {
        & .rsb-tabBtn.is-tab-current {
            &[data-tab-single="true"] {
                width: 100%;
            }
        }
    }
</style>
