<template>
    <transition name="fade">
        <div class="p-modal" data-modal-id="time-table-01">
            <div class="p-modal-inr">
                <div class="timetable">
                    <div class="timetable-header">
                        <div class="icon">
                            <i class="icon-bus"></i>
                        </div>
                        <div>
                            <h2 class="bus-stop">
                                <span class="bus-stop-inr">{{ modalTitle }}</span>
                            </h2>
                            <p class="c-name">
                                <span class="c-name-inr">{{ modalText }}</span>
                            </p>
                        </div>
                        <button type="button" class="p-modal-close" @click="modalClose">
                            <span class="cross"></span>
                        </button>
                    </div>

                    <div v-if="modalData" class="sw-tab">
                        <div class="sw-tab-list">
                            <button v-if="modalData.weekdays" type="button" class="tt-tabBtn sw-tab-btn" :class="tabActive === 'weekday' ? 'is-tab-current' : ''" data-tab-id="weekday" @click="tabActive = 'weekday'">
                                平日
                            </button>
                            <button v-if="modalData.holidays" type="button" class="tt-tabBtn sw-tab-btn" :class="tabActive === 'holiday' ? 'is-tab-current' : ''" data-tab-id="holiday" @click="tabActive = 'holiday'">
                                土・日・祝日
                            </button>
                        </div><!-- /.sw-tab-list -->

                        <div class="sw-tab-content">
                            <div class="sw-tab-panel" v-if="modalData.weekdays" :class="tabActive === 'weekday' ? 'is-tab-current' : ''" data-tab-id="weekday">
                                <div class="timetable-table">
                                    <div v-for="(weekday, loopIndex) in loop.weekdays" :key="loopIndex + 1" class="row">
                                        <time v-for="(time, timeIndex) in weekday" :key="timeIndex + 1">{{ time }}</time>
                                    </div>
                                </div>
                            </div>
                            <div class="sw-tab-panel" v-if="modalData.holidays" :class="tabActive === 'holiday' ? 'is-tab-current' : ''" data-tab-id="holiday">
                                <div class="timetable-table">
                                    <div v-for="(holiday, loopIndex) in loop.holidays" :key="loopIndex + 1" class="row">
                                        <time v-for="(time, timeIndex) in holiday" :key="timeIndex + 1">{{ time }}</time>
                                    </div>
                                </div>
                            </div>
                        </div><!-- /.sw-tab-content -->
                    </div><!-- /.sw-tab -->

                </div><!-- /.timetable -->
            </div><!-- /.p-modal-inr -->
        </div><!-- /.p-modal -->
    </transition>
</template>

<script>
    export default {
        name: "ModalWrap",
        props: {
            modalTitle: {
                type: String
            },
            modalText: {
                type: String
            },
            modalData: {
                type: Object
            }
        },
        data: function () {
            return {
                tabActive: 'weekday',
                loop: {
                    weekdays: [],
                    holidays: []
                }
            }
        },
        mounted: function () {

        },
        watch: {
            modalData: function () {
                this.loop.weekdays = [];
                this.loop.holidays = [];
                if (this.modalData) {
                    if (this.modalData.weekdays) {
                        // this.loop.weekdays = this.modalData.weekdays;
                        this.loop.weekdays = this.divideArrIntoPieces(this.modalData.weekdays, 3);
                    }
                    if (this.modalData.holidays) {
                        this.loop.holidays = this.divideArrIntoPieces(this.modalData.holidays, 3);
                    }
                }
            }
        },
        methods: {
            modalClose: function () {
                this.$emit('modal-close');
            },
            divideArrIntoPieces: function (arr, n) {
                let list = [];
                let childlist = [];
                let count = 0;
                let total = 0;
                let num = arr.length;

                arr.forEach(function (val) {
                    count += 1;
                    total += 1;
                    childlist.push(val);

                    if (count === n) {
                        list.push(childlist);
                        childlist = [];
                        count = 0;
                    }

                    if (total === num) {
                        if (childlist) {
                            list.push(childlist);
                        }
                    }
                });

                return list;
            }
        }
    }
</script>

<style lang="scss" scoped>
    .fade {
        &-enter-active, &-leave-active {
            will-change: opacity;
            transition: opacity 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
        }
        &-enter, &-leave-to {
            opacity: 0;
        }
    }
</style>
