<template>
    <div>
        <header-component></header-component>
        <search-component></search-component>
        <footer-component></footer-component>
    </div>
</template>

<script>
    import HeaderComponent from './components/wrap/HeaderComponent'
    import SearchComponent from './components/wrap/SearchComponent'
    import FooterComponent from './components/wrap/FooterComponent'

    export default {
        name: 'App',
        components: {
            HeaderComponent,
            SearchComponent,
            FooterComponent
        }
    }
</script>

<style>

</style>
