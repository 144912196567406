<template>
    <div class="p-keyvisual-map">
        <img src="../../assets/img/mv_map.png" alt="">
    </div>
</template>

<script>
    export default {
        name: "MvMap"
    }
</script>

<style lang="scss" scoped>

</style>
