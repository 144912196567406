import { render, staticRenderFns } from "./SearchPostCampaign.vue?vue&type=template&id=3648450c&scoped=true&"
import script from "./SearchPostCampaign.vue?vue&type=script&lang=js&"
export * from "./SearchPostCampaign.vue?vue&type=script&lang=js&"
import style0 from "./SearchPostCampaign.vue?vue&type=style&index=0&id=3648450c&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3648450c",
  null
  
)

export default component.exports