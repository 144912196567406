<template>
    <div id="result">
        <div class="serchSec">
            <div class="inr serchSec-inr">
                <div class="serchSec-select">
                    <i class="serchSec-select__icon">
                        <img src="../../assets/img/map_pin_s.svg" alt="">
                    </i>
                    <select2 id="select-start" v-model="values.start" :options="select2.options.startArea" :placeholder="select2.placeholder.start" :settings="select2.settings" @change="selectStartEvent($event)"></select2>
                </div>
                <div class="serchSec-select">
                    <i class="serchSec-select__icon">
                        <img src="../../assets/img/map_pin_g.svg" alt="">
                    </i>
                    <select2 id="select-goal" v-model="values.goal" :options="select2.options.goalArea" :placeholder="select2.placeholder.goal" :settings="select2.settings" @change="selectGoalEvent($event)"></select2>
                </div>
                <button type="button" class="serchSec-btn" @click="onClickSearch">検索</button>
            </div>
        </div>
        <main>
            <search-result v-if="show.result" :base-data="values.baseData" :route-data="values.routeData"></search-result>
            <search-post-tourist-info v-if="values.post.tourist" :posts="values.post.tourist"></search-post-tourist-info>
            <search-post-campaign v-if="values.post.campaign" :posts="values.post.campaign"></search-post-campaign>
        </main>
    </div>
</template>

<script>
    import SearchResult from '../search/SearchResult'
    import SearchPostCampaign from '../search/SearchPostCampaign'
    import SearchPostTouristInfo from '../search/SearchPostTouristInfo'
    import Select2 from 'v-select2-component'

    export default {
        name: "SearchComponent",
        components: {
            Select2,
            SearchResult,
            SearchPostCampaign,
            SearchPostTouristInfo
        },
        data: function () {
            return {
                values: {
                    start: null,
                    goal: null,
                    baseData: null,
                    routeData: null,
                    post: {
                        campaign: null,
                        tourist: null
                    }
                },
                show: {
                    result: false
                },
                select2: {
                    placeholder: {
                        start: '出発地を選択してください',
                        goal: '目的地を選択してください'
                    },
                    settings: {
                        width: '100%',
                        minimumResultsForSearch: Infinity
                    },
                    options: {
                        startArea: [],
                        goalArea: []
                    }
                }
            }
        },
        mounted: function () {
            const firstStartAreas = [];
            const firstGoalAreas = [];
            this.axios.get(`./data/from-to.json?${new Date().getTime()}`)
                .then(function (response) {
                    if (response.data) {
                        if (response.data.from) {
                            if (response.data.from.groups) {
                                response.data.from.groups.forEach(function (list) {
                                    let childrens = [];
                                    list.list.forEach(function (child) {
                                        childrens.push({
                                            id: child.value,
                                            text: child.name
                                        });
                                    });
                                    firstStartAreas.push({
                                        text: list.name,
                                        children: childrens
                                    });
                                });
                            }
                        }
                        if (response.data.to) {
                            if (response.data.to.groups) {
                                response.data.to.groups.forEach(function (list) {
                                    let childrens = [];
                                    list.list.forEach(function (child) {
                                        childrens.push({
                                            id: child.value,
                                            text: child.name
                                        });
                                    });
                                    firstGoalAreas.push({
                                        text: list.name,
                                        children: childrens
                                    });
                                });
                            }
                        }
                    }
                })
                .catch(function (e) {
                    /* eslint-disable no-console */
                    console.error(e);
                });
            this.select2.options.startArea = firstStartAreas;
            this.select2.options.goalArea = firstGoalAreas;

            // default posts
            this.getCampaignPosts('default.json');
            this.getTouristPosts('default.json');
        },
        methods: {
            selectStartEvent: function (val) {
                this.values.start = val;
            },
            selectGoalEvent: function (val) {
                this.values.goal = val;
            },
            displaySearchResult: function () {
                this.show.result = true;
                this.$scrollTo('#result', 1000);
            },
            onClickSearch: function () {
                this.show.result = false;
                this.values.baseData = null;
                this.values.routeData = null;
                if (this.values.start) {
                    if (this.values.goal) {
                        this.axios.get(`./data/pattern/${this.values.start}-${this.values.goal}.json?${new Date().getTime()}`)
                            .then(function (response) {
                                if (response.data) {
                                    this.values.baseData = response.data;
                                    if (response.data.route) {
                                        this.values.routeData = [];
                                        // response.data.route.forEach(async function (file) {
                                        //     // this.axios.get(`./config/result/parts/${file}`)
                                        //     //     .then(function (response) {
                                        //     //         if (response.data) {
                                        //     //             this.values.routeData.push(response.data);
                                        //     //         }
                                        //     //     }.bind(this))
                                        //     //     .catch(function (e) {
                                        //     //         /* eslint-disable no-console */
                                        //     //         console.error(e);
                                        //     //     });
                                        //     await this.setRouteData(file);
                                        // }.bind(this));

                                        // for (let file of response.data.route) {
                                        //     // this.setRouteData(file);
                                        //     setTimeout(function () {
                                        //         console.log(file);
                                        //     }, 3000);
                                        // }
                                        // let test = async function () {
                                        //     for (let file of response.data.route) {
                                        //         await this.setRouteData(file);
                                        //     }
                                        // }.bind(this);
                                        // test();

                                        let setupRouteData = async function () {
                                            for (let i in response.data.route) {
                                                await this.axios.get(`./data/place/${response.data.route[i]}?${new Date().getTime()}`)
                                                    .then(function (obj) {
                                                        this.values.routeData[i] = obj.data;
                                                        return Promise.resolve(obj);
                                                    }.bind(this))
                                                    .catch(function (e) {
                                                        /* eslint-disable no-console */
                                                        console.error(e);
                                                    });
                                            }
                                        }.bind(this);

                                        setupRouteData().then(function () {
                                            this.displaySearchResult();
                                        }.bind(this));
                                    } else {
                                        this.displaySearchResult();
                                    }
                                }
                            }.bind(this))
                            .catch(function (e) {
                                /* eslint-disable no-console */
                                console.error(e);
                            });
                    }
                }
            },
            getJsonRouteData: async function (file) {
                let routeData = null;
                await this.axios.get(`./data/place/${file}?${new Date().getTime()}`)
                    .then(function (response) {
                        if (response.data) {
                            routeData = response.data;
                        }
                    })
                    .catch(function (e) {
                        /* eslint-disable no-console */
                        console.error(e);
                    });

                return routeData;
            },
            setRouteData: async function (file) {
                this.values.routeData.push(await this.getJsonRouteData(file));
                // this.values.routeData[file] = await this.getJsonRouteData(file);
            },
            getCampaignPosts: async function (file) {
                let campaignPosts = null;

                await this.axios.get(`./data/post/campaign/${file}?${new Date().getTime()}`)
                    .then(function (response) {
                        if (response.data) {
                            if (response.data.post) {
                                campaignPosts = response.data.post;
                            }
                        }
                    })
                    .catch(function (e) {
                        /* eslint-disable no-console */
                        console.error(e);
                    });

                this.values.post.campaign = campaignPosts;
            },
            getTouristPosts: async function (file) {
                let touristPosts = null;

                await this.axios.get(`./data/post/tourist/${file}?${new Date().getTime()}`)
                    .then(function (response) {
                        if (response.data) {
                            if (response.data.post) {
                                touristPosts = response.data.post;
                            }
                        }
                    })
                    .catch(function (e) {
                        /* eslint-disable no-console */
                        console.error(e);
                    });

                this.values.post.tourist = touristPosts;
            }
        }
    }
</script>

<style lang="scss" scoped>
.serchSec-select__icon {
    img {
        width: 100%;
    }
}
</style>
