<template>
    <div class="p-keyvisual">
        <mv-char></mv-char>
        <mv-map></mv-map>
    </div>
</template>

<script>
    import MvChar from '../mv/MvChar'
    import MvMap from '../mv/MvMap'

    export default {
        name: "HeaderComponent",
        components: {
            MvChar,
            MvMap
        }
    }
</script>

<style lang="scss" scoped>

</style>
