<template>
    <footer class="l-footer">
        <div class="l-footer__inr">
            <a href="https://www.towada.travel/ja/visit" target="_blank" class="l-footer__logo">
                <img src="../../assets/img/logo_w.png" alt="logo">
            </a>
            <ul class="l-footer__sns">
                <li>
                    <a href="https://www.facebook.com/TowadaandOiraseTourismBureau/" target="_blank">
                        <i class="icon-facebook"></i>
                    </a>
                </li>
                <li>
                    <a href="https://twitter.com/towada_kankou" target="_blank">
                        <i class="icon-twitter"></i>
                    </a>
                </li>
                <li>
                    <a href="https://www.instagram.com/towada_travel/" target="_blank">
                        <i class="icon-instagram"></i>
                    </a>
                </li>
            </ul>
            <small class="copyright">Copyright © Travel Towada. All rights reserved.</small>
        </div>
    </footer>
</template>

<script>
    export default {
        name: "FooterComponent"
    }
</script>

<style lang="scss" scoped>
    .l-footer__inr {
        img {
            width: 100%;
        }
    }
</style>
