<template>
    <header class="p-keyvisual-char">
        <div class="logo">
            <img src="../../assets/img/logo.png" alt="">
        </div>
        <h1 class="main-txt">
            <img src="../../assets/img/mv_text.svg" alt="十和田・奥入瀬交通ルート検索">
        </h1>
        <h2 class="sub-txt">
            出発地と目的地を選択するだけ!<br>
            簡単におすすめの交通ルートをGETしよう
        </h2>
    </header>
</template>

<script>
    export default {
        name: "MvChar"
    }
</script>

<style lang="scss" scoped>
    .p-keyvisual-char {
        img {
            width: 100%;
        }
    }
</style>
